<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-model-table model-name="radiologies" model-title="รายการการตรวจทางรังสีวินิจฉัย"
          :model-api="['models','Radiology','Radiologies']"
          api-order-by="studyDateTime,desc"
          :headers="headers"
          :insertable="false"
          server-pagination
        >
          <template v-slot:item.modality="props">
            {{ props.item.modality }} - {{ props.item.description }}
          </template>
          <template v-slot:item.studyDateTime="props">
            <v-label-datetime :dateTime="props.item.studyDateTime" tiny-date-time></v-label-datetime>
          </template>
          <template v-slot:item.productCode="props">
            <v-edit-dialog
              :return-value.sync="props.item.productCode"
              @save="props.actions.saveData(props.item)"
              v-if="props.item.reportingDoctorCode"
              large
            > 
              <v-icon v-if="!props.item.productCode">mdi-cart-plus</v-icon>
              <v-icon v-else>mdi-cart-outline</v-icon>
              <template v-if="props.item.product">{{ props.item.product.productName }}</template>
              <template v-slot:input>
                <v-master-field v-model="props.item.productCode" 
                  :custom-api="['models','Master','Products','query']" custom-api-text="productName" 
                  :filter-text="{productType: 'radiology'}"
                  custom-api-value="productCode" label="รายการสินค้า" 
                  customApiEagerLoad
                  show-code
                ></v-master-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.reportingDoctorCode="props">
            <v-edit-dialog
              :return-value.sync="props.item.reportingDoctorCode"
              @save="props.actions.saveData(props.item)"
              v-if="!props.item.reportDocumentId"
              large
            > 
              <v-icon v-if="!props.item.reportingDoctorCode">mdi-account-plus</v-icon>
              <v-icon v-else>mdi-account-edit</v-icon>
              <template v-if="props.item.reporting_doctor">{{ props.item.reporting_doctor.nameTH }}</template>
              <template v-slot:input>
                <v-master-field v-model="props.item.reportingDoctorCode" :custom-api="['models','Master','Doctors','query']"
                  custom-api-text="nameTH" custom-api-value="doctorCode"
                  customApiEagerLoad
                  label="รังสีแพทย์" show-code
                ></v-master-field>
              </template>
            </v-edit-dialog>
            <template v-else>{{ props.item.reporting_doctor.nameTH }}</template>
          </template>
          <template v-slot:item.reportingType="props">
            <v-edit-dialog
              :return-value.sync="props.item.reportingType"
              @save="props.actions.saveData(props.item)"
              v-if="props.item.reportingDoctorCode"
              large
            > 
              <v-icon v-if="!props.item.reportingType">add</v-icon>
              <v-icon v-else>edit</v-icon>
              <template v-if="props.item.reportingType"><v-label-master group-key="reportingType" :item-code="props.item.reportingType"></v-label-master></template>
              <template v-slot:input>
                <v-master-field v-model="props.item.reportingType" label="ชนิดการรายงานผล"
                  :groupKey="$store.getters.getGroupKey('reportingType')"
                  show-code
                ></v-master-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.reportDocumentId="props">
            <template v-if="props.item.report_document && props.item.report_document.status=='approved'">
              <v-btn color="primary" @click="viewDocument(props.item.report_document)"><v-icon>mdi-file-document-outline</v-icon>ผลอ่าน</v-btn>
            </template>
          </template>
          <template v-slot:item.action="props">
            <v-btn color="primary" @click="openPacs(props.item.uid)">เปิด</v-btn>
          </template>
        </v-model-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import helperPacs from '@/modules/helperPacs'

export default {
  data: ()=>({
    headers: [
      {
        text: 'วันที่ทำการตรวจ',
        value: 'studyDateTime',
        class: 'body-2'
      },
      {
        text: 'Accession No',
        value: 'accessionNumber',
        class: 'body-2'
      },
      {
        text: 'ชนิด - ส่วนที่ตรวจ',
        value: 'modality',
        class: 'body-2'
      },
      {
        text: 'รหัสรายการ',
        value: 'productCode',
        class: 'body-2'
      },
      {
        text: 'รังสีแพทย์',
        value: 'reportingDoctorCode',
        class: 'body-2'
      },
      {
        text: 'ชนิดรายงาน',
        value: 'reportingType',
        class: 'body-2'
      },
      {
        text: 'รายงานผล',
        value: 'reportDocumentId',
        class: 'body-2'
      },
      {
        text: 'จำนวนภาพ',
        value: 'imageCount',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ]
  }),
  methods: {
    openPacs(uid) {
      helperPacs.openPacs(uid)
    },
    viewDocument(document) {
      this.$documentViewer(document,{fullscreen: false})
    }
  },
}
</script>

<style lang="css" scoped>
</style>
